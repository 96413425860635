import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useAlert } from 'react-alert';

const AddClienteForm = ({ onSubmitSuccess }) => {
	const alert = useAlert();
	const [clientEmailsForm, setClientEmailsForm] = useState([]);
	const [keyCount, setKeyCount] = useState(0);

	useEffect(() => {
		addClientEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const submitClient = async (form, values) => {
		if (form.valid) {
			if (clientEmailsForm.length > 0) {
				try {
					const newClient = {
						usuario: values.usuario,
						password: values.password,
						cliente: values.cliente,
						capacidad: values.capacidad,
						emails: getClientEmails(values)
					};
					const { data } = await axiosConfig({
						method: 'post',
						url: '/data-system/clientes/nuevo',
						data: newClient
					});

					if (data === 'OK') {
						alert.show(`El cliente ${values.cliente} fue agregado correctamente.`, {
							type: 'success'
						});
						onSubmitSuccess();
					}
				} catch (error) {
					alert.show('Hubo un problema agregando al cliente, intentelo mas tarde.', {
						type: 'error'
					});
				}
			} else {
				alert.show('No puedes agregar un cliente sin al menos un email', {
					type: 'error'
				});
			}
		}
	};
	const getKey = () => {
		setKeyCount(keyCount + 1);
		return keyCount;
	};
	const addClientEmail = () => {
		const clientEmail = {
			id: getKey()
		};

		const newClientEmails = Object.assign([], clientEmailsForm);
		newClientEmails.push(clientEmail);
		setClientEmailsForm(newClientEmails);
	};
	const removeClientEmail = index => {
		const newClientEmails = Object.assign([], clientEmailsForm);
		newClientEmails.splice(index, 1);
		setClientEmailsForm(newClientEmails);
	};
	const getClientEmails = values => {
		let clientEmailRegex = new RegExp(/emailCliente\d+/);

		const clientEmailKeys = Object.keys(values).filter(key => clientEmailRegex.test(key));

		const clientEmails = [];

		for (let index = 0; index < clientEmailKeys.length; index++) {
			clientEmails.push({
				email: values[clientEmailKeys[index]]
			});
		}

		return clientEmails;
	};
	return (
		<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
			<div className="flex flex-col w-full p-4 mb-4">
				<div className="flex flex-col items-center">
					<p className="text-2xl mt-2">Agregar Cliente</p>
				</div>
				<WafoForm formId={'newEntrieForm'} onSubmit={submitClient}>
					<div className="w-full flex flex-col lg:flex-row w-full">
						<div className="flex flex-col w-full">
							<div className="flex flex-col xl:flex-row w-full pr-4 lg:pr-0">
								<div className="flex flex-col md:flex-row w-full">
									<WafoFormInput
										name="usuario"
										placeholder="Usuario"
										label="Usuario"
										customClass="form-group-lg"
										type="text"
										validations={{
											required: {
												value: true,
												message: 'El campo Usuario es requerido'
											}
										}}
									/>
									<WafoFormInput
										name="password"
										placeholder="Contraseña"
										label="Contraseña"
										customClass="form-group-lg"
										type="password"
										validations={{
											required: {
												value: true,
												message: 'El campo Contraseña es requerido'
											}
										}}
										extraProps={{
											autoComplete: 'off'
										}}
									/>
								</div>
								<div className="flex flex-col md:flex-row w-full">
									<WafoFormInput
										name="cliente"
										placeholder="Cliente"
										label="Cliente"
										customClass="form-group-lg"
										type="text"
										multiple
										validations={{
											required: {
												value: true,
												message: 'El campo Cliente es requerido'
											}
										}}
									/>
									<WafoFormInput
										name="capacidad"
										placeholder="Capacidad Bultos"
										label="Capacidad"
										type="number"
										customClass="form-group-lg"
										extraProps={{
											min: 0
										}}
										validations={{
											required: {
												value: true,
												message: 'El campo Capacidad Bultos es requerido'
											}
										}}
									/>
								</div>
							</div>
							<div className="flex flex-row">
								<button className="ml-4 h-10 mt-12 button btn-lg bg-green-600" type="submit">
									Agregar Cliente
								</button>
								<button
									className="ml-4 h-10 mt-12 button btn-lg bg-blue-600"
									onClick={event => {
										event.preventDefault();
										addClientEmail();
									}}
								>
									Agregar Email
								</button>
							</div>
						</div>
						<div className="flex flex-col w-full ml-4 md:mt-0">
							{clientEmailsForm.map((email, index) => {
								return (
									<div key={email.id} className="flex flex-row w-full">
										<WafoFormInput
											name={`emailCliente${email.id}`}
											placeholder="Email"
											label="Email"
											customClass="form-group-lg"
											type="text"
											validations={{
												required: {
													value: true,
													message: 'El campo Email es requerido'
												},
												email: {
													value: true,
													message: 'Debes ingresar un Email valido'
												}
											}}
										/>
										<button
											className="ml-4 h-10 mt-12 button btn-md bg-red-700"
											onClick={event => {
												event.preventDefault();
												removeClientEmail(index);
											}}
										>
											Eliminar
										</button>
									</div>
								);
							})}
						</div>
					</div>
				</WafoForm>
			</div>
		</div>
	);
};

export default AddClienteForm;
