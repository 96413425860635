import React, { useState, useEffect } from 'react';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import axiosConfig from '#utils/axiosConfig';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditUsuario = ({ location: { state } }) => {
	const [submitted, setSubmitted] = useState(false);
	const [defaultUser, setDefaultUser] = useState({});
	const [userTypes, setUserTypes] = useState([]);
	const alert = useAlert();

	useEffect(() => {
		const getUser = async () => {
			const userInfo = await axiosConfig({
				method: 'get',
				url: '/Data-System/Usuarios/getUsuario',
				params: { idUsuario: state.userId }
			});

			setDefaultUser({
				username: userInfo.Username,
				userType: userInfo.tipo_usuario.Id_Tipo_Usuario,
				userId: userInfo.Id_Usuario
			});
		};
		const getUserTypes = async () => {
			let data = await axiosConfig({
				method: 'get',
				url: '/Data-System/Usuarios/Tipos'
			});

			data = data.map(obj => {
				return { display: obj.Tipo_Usuario, value: String(obj.Id_Tipo_Usuario) };
			});

			setUserTypes(data);
		};
		if (state) {
			getUserTypes();
			getUser();
		}
	}, [state]);

	useEffect(() => {
		const getUserTypes = async () => {
			let data = await axiosConfig({
				method: 'get',
				url: '/Data-System/Usuarios/Tipos'
			});

			data = data.map(obj => {
				return { display: obj.Tipo_Usuario, value: String(obj.Id_Tipo_Usuario) };
			});

			setUserTypes(data);
		};
		getUserTypes();
	}, []);

	const submitUser = async (form, values) => {
		if (form.valid) {
			try {
				const { data } = await axiosConfig({
					method: 'put',
					url: '/Data-System/Usuarios/Editar',
					data: {
						username: values.username,
						password: values.password,
						tipoUsuario: values.userType,
						idUsuario: values.userId
					}
				});

				if (data === 'OK') {
					alert.show(`El Usuario ${values.username} fue editado correctamente.`, {
						type: 'success'
					});
					setSubmitted(true);
				}
			} catch (error) {
				alert.show('Hubo un problema editando al usuario, intentelo mas tarde.', {
					type: 'error'
				});
			}
		}
	};

	if (submitted) {
		return <Redirect to="/Data-System/Catalogos/Usuarios" />;
	}
	if (state) {
		if (!defaultUser) {
			return <div>Cargando</div>;
		}
		return (
			<div className="w-full lg:w-3/4 mx-auto bg-gray-100 shadow-md mt-4">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Usuario</p>
					</div>
					<WafoForm values={defaultUser} formId="editUserForm" onSubmit={submitUser}>
						<div className="flex flex-col mx-auto w-full lg:w-3/4 items-center">
							<div className="flex flex-col md:flex-row w-full pr-2">
								<WafoFormInput
									name="userId"
									label="Id"
									placeholder="Id"
									extraProps={{
										disabled: true
									}}
								/>
								<WafoFormInput
									name="username"
									label="Usuario"
									placeholder="Usuario"
									customClass="form-group-lg"
									validations={{
										required: {
											value: true,
											message: 'El campo Usuario es requerido'
										}
									}}
								/>
							</div>
							<div className="flex flex-col md:flex-row w-full pr-2">
								<WafoFormInput
									name="password"
									label="Contraseña"
									placeholder="Contraseña"
									type="password"
									customClass="form-group-lg"
								/>
								<WafoFormSelect
									name="userType"
									label="Tipo Usuario"
									placeholder="Tipo Usuario"
									customClass="form-group-lg"
									defaultValue="Seleccione una Opcion"
									options={userTypes}
									validations={{
										required: {
											value: true,
											message: 'El campo Tipo Usuario es requerido'
										}
									}}
								/>
							</div>

							<button className="button btn-lg bg-green-700 mt-3 mb-2">Editar Usuario</button>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/Data-System/Catalogos/Usuarios" />;
};

export default EditUsuario;
