import React, { useState, useEffect } from 'react';
import axiosConfig from '#utils/axiosConfig';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useAlert } from 'react-alert';
import { Redirect } from 'react-router';

const EditCliente = ({ location: { state } }) => {
	const alert = useAlert();
	const [clientEmailsForm, setClientEmailsForm] = useState([]);
	const [keyCount, setKeyCount] = useState(0);
	const [submitted, setSubmitted] = useState(false);
	const [defaultClient, setDefaultCliente] = useState();

	useEffect(() => {
		const getCliente = async () => {
			const clientInfo = await axiosConfig({
				method: 'get',
				url: '/data-system/clientes/getCliente',
				params: { idCliente: state.clientId }
			});

			setDefaultCliente({
				usuario: clientInfo.usuario_cliente.Username,
				cliente: clientInfo.Nombre_Cliente,
				capacidad: clientInfo.Capacidad_Bultos
			});

			let clientEmails = {};

			const newClientEmails = [];
			clientInfo.email_cliente.map(({ Email, Id_Email }, index) => {
				clientEmails = {
					...clientEmails,
					[`emailCliente${index}`]: Email,
					[`idEmail${index}`]: Id_Email
				};
				const clientEmail = {
					id: index
				};
				newClientEmails.push(clientEmail);
				addClientEmail();
				return null;
			});
			setDefaultCliente(prev => {
				return {
					...prev,
					...clientEmails
				};
			});
			setClientEmailsForm(newClientEmails);
			setKeyCount(newClientEmails.length);
		};
		if (state) {
			getCliente();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const submitClient = async (form, values) => {
		if (form.valid) {
			if (clientEmailsForm.length > 0) {
				try {
					const newClient = {
						idCliente: state.clientId,
						usuario: values.usuario,
						password: values.password,
						cliente: values.cliente,
						capacidad: values.capacidad,
						emails: getClientEmails(values)
					};
					const { data } = await axiosConfig({
						method: 'put',
						url: '/data-system/clientes/actualizar',
						data: newClient
					});

					if (data === 'OK') {
						alert.show(`El cliente ${values.cliente} fue modificado correctamente.`, {
							type: 'success'
						});
						setSubmitted(true);
					}
				} catch (error) {
					alert.show('Hubo un problema al modificar el cliente, intentelo mas tarde.', {
						type: 'error'
					});
				}
			} else {
				alert.show('El cliente no puede ser modificado sin emails', {
					type: 'error'
				});
			}
		}
	};
	const getKey = () => {
		setKeyCount(keyCount + 1);
		return keyCount;
	};
	const addClientEmail = async () => {
		const clientEmail = {
			id: getKey()
		};

		const newClientEmails = Object.assign([], clientEmailsForm);
		newClientEmails.push(clientEmail);
		setClientEmailsForm(newClientEmails);
	};
	const removeClientEmail = index => {
		const newClientEmails = Object.assign([], clientEmailsForm);
		newClientEmails.splice(index, 1);
		setClientEmailsForm(newClientEmails);
	};

	const getClientEmails = values => {
		let clientEmailRegex = new RegExp(/emailCliente\d+/);
		let clientEmailIdRegex = new RegExp(/idEmail\d+/);

		const clientEmailKeys = Object.keys(values).filter(key => clientEmailRegex.test(key));
		const clientEmailIdKeys = Object.keys(values).filter(key => clientEmailIdRegex.test(key));

		const clientEmails = [];

		for (let index = 0; index < clientEmailKeys.length; index++) {
			clientEmails.push({
				email: values[clientEmailKeys[index]],
				id: values[clientEmailIdKeys[index]]
			});
		}

		return clientEmails;
	};

	if (submitted) {
		return <Redirect to="/data-system/catalogos/clientes" />;
	}
	if (state) {
		if (!defaultClient) {
			return <div>Cargando</div>;
		}
		return (
			<div className="flex flex-col bg-gray-100 shadow-md mt-3 overflow-y-auto">
				<div className="flex flex-col w-full p-4 mb-4">
					<div className="flex flex-col items-center">
						<p className="text-2xl mt-2">Editar Cliente</p>
					</div>
					<WafoForm values={defaultClient} formId={'newEntrieForm'} onSubmit={submitClient}>
						<div className="w-full flex flex-col lg:flex-row w-full">
							<div className="flex flex-col w-full">
								<div className="flex flex-col xl:flex-row w-full pr-4 lg:pr-0">
									<div className="flex flex-col md:flex-row w-full">
										<WafoFormInput
											name="usuario"
											placeholder="Usuario"
											label="Usuario"
											customClass="form-group-lg"
											type="text"
											validations={{
												required: {
													value: true,
													message: 'El campo Usuario es requerido'
												}
											}}
										/>
										<WafoFormInput
											name="password"
											placeholder="Contraseña"
											label="Contraseña"
											customClass="form-group-lg"
											type="password"
											extraProps={{
												autoComplete: 'off'
											}}
										/>
									</div>
									<div className="flex flex-col md:flex-row w-full">
										<WafoFormInput
											name="cliente"
											placeholder="Cliente"
											label="Cliente"
											customClass="form-group-lg"
											type="text"
											validations={{
												required: {
													value: true,
													message: 'El campo Cliente es requerido'
												}
											}}
										/>
										<WafoFormInput
											name="capacidad"
											placeholder="Capacidad Bultos"
											label="Capacidad"
											type="number"
											customClass="form-group-lg"
											extraProps={{
												min: 0
											}}
											validations={{
												required: {
													value: true,
													message: 'El campo Capacidad Bultos es requerido'
												}
											}}
										/>
									</div>
								</div>
								<div className="flex flex-row">
									<button className="ml-4 h-10 mt-12 button btn-lg bg-green-600" type="submit">
										Editar Cliente
									</button>
									<button
										className="ml-4 h-10 mt-12 button btn-lg bg-blue-600"
										onClick={event => {
											event.preventDefault();
											addClientEmail();
										}}
									>
										Agregar Email
									</button>
								</div>
							</div>
							<div className="flex flex-col w-full ml-4 md:mt-0">
								{clientEmailsForm.map((email, index) => {
									return (
										<div key={email.id} className="flex flex-row w-full">
											<WafoFormInput
												name={`idEmail${email.id}`}
												placeholder="IdEmail"
												label="Id"
												customClass=""
												type="text"
												extraProps={{ disabled: true }}
											/>
											<WafoFormInput
												name={`emailCliente${email.id}`}
												placeholder="Email"
												label="Email"
												customClass="form-group-md"
												type="text"
												validations={{
													required: {
														value: true,
														message: 'El campo Cliente es requerido'
													},
													email: {
														value: true,
														message: 'Debes ingresar un Email valido'
													}
												}}
											/>
											<button
												className="ml-4 h-10 mt-12 button btn-md bg-red-700"
												onClick={event => {
													event.preventDefault();
													removeClientEmail(index);
												}}
											>
												Eliminar
											</button>
										</div>
									);
								})}
							</div>
						</div>
					</WafoForm>
				</div>
			</div>
		);
	}
	return <Redirect to="/data-system/catalogos/clientes" />;
};

export default EditCliente;
